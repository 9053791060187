@charset "utf-8";

@import "../core/variables";


/* login.css */

/* category styles
**************************************************/
#backdrop {
  position:absolute;
  top:228px;
  z-index:1;
  width:100%;
  height:300px;
  /* box-shadow:0 1px 6px #ddd inset; */
}

#container {
  margin:0 auto 0;
  background: #fff;
  padding: 8px 16px;
}

.btnWrap {
  text-align:center;
  padding-bottom:20px;
}

/* loginForm */
.loginForm .mc_box {
  margin-top:60px;
}

.loginForm .newMail {
  margin:0 0 10px;
  color:#333;
  font-size:14px;
}
  .loginForm .newMail em {
    font-size:16px;
    font-weight: bold;
  }

/* resetPassword */
.resetPassword .mc_box {
  margin-top:20px;
  margin-right:0;
  margin-left:0;
}

.resetPassword .mc_table {
  width:100%;
}
  .resetPassword .mc_table th {
    width:150px;
  }

/* newPassword */
.newPassword .mc_box {
  margin-top:20px;
  margin-right:0;
  margin-left:0;
}

.newPassword .mc_table {
  width:100%;
}
.newPassword .mc_table th {
  width:150px;
}

.newPassword .mc_notice {
  margin:5px 0 0;
}


